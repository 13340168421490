@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
    font-family: 'Wulkan Display';
    src: url('/src/assets/Font/wulkan/WulkanDisplayBold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Wulkan Display';
    src: url('/src/assets/Font/wulkan/WulkanDisplayBlack.woff2') format('woff2'),
        url('/src/assets/Font/wulkan/WulkanDisplayBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Wulkan Display';
    src: url('/src/assets/Font/wulkan/WulkanDisplayLight.woff2') format('woff2'),
        url('/src/assets/Font/wulkan/WulkanDisplayLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Wulkan Display';
    src: url('/src/assets/Font/wulkan/WulkanDisplaySemiBold.woff2') format('woff2'),
        url('/src/assets/Font/wulkan/WulkanDisplaySemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Wulkan Display';
    src: url('/src/assets/Font/wulkan/WulkanDisplayMedium.woff2') format('woff2'),
        url('/src/assets/Font/wulkan/WulkanDisplayMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


$black-color : #000;
$white-color : #fff;
$primary-btn-bg: linear-gradient(90deg,
        #C18C2C 0%,
        #FCF38A 50.52%,
        #C18C2C 100%);
$primary-txt-color : #1C1717;
$para-txt : #1e1e1e;
$golden-color : #e4c499;

$work-sans-font: 'Work Sans',
    sans-serif;
$wulkan-fonts: 'Wulkan Display';


$breakpoints: (
    "db-xl": 3000px,
    "xl": 2200px,
    "lg": 1800px,
    "bw": 1500px,
    "sm-lp": 1300px,
    "large-tab": 1080px,
    "md": 912px,
    "medium": 768px,
    "sm": 526px,
    "xs": 376px,
    "1920" : 1920px,
    "1440" : 1440px,
    "1366": 1366px,
    "1280" : 1280px,
    "360" : 360px,
    "375" : 375px,
    "393" : 393px,
    "414" : 414px,
    "430" : 430px
);

body {
    // background: #f7eed9 !important;
    background: $golden-color !important;

    // font-family: $work-sans-font !important;
    overflow-x: hidden;
    max-width: 1920px;
    margin: auto !important;
}

html {
    overflow-x: hidden;
}

@mixin extra-large {
    @media (min-width: map-get($breakpoints, "db-xl")) {
        @content;
    }
}

@mixin large-device {
    @media (min-width: map-get($breakpoints, "xl")) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: map-get($breakpoints, "lg")) {
        @content;
    }
}

@mixin laptop {
    @media (max-width: map-get($breakpoints, "bw")) {
        @content;
    }
}

@mixin large-tab {
    @media (max-width: map-get($breakpoints, "large-tab")) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: map-get($breakpoints, "md")) {
        @content;
    }
}

@mixin smartphone {
    @media (max-width: map-get($breakpoints, "medium")) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: map-get($breakpoints, "sm")) {
        @content;
    }
}

@mixin xs-mobile {
    @media (max-width: map-get($breakpoints, "xs")) {
        @content;
    }
}

/* Standard breakpoints */

@mixin brakpoints-1920 {
    @media (max-width: map-get($breakpoints, "1920")) {
        @content;
    }
}


@mixin brakpoints-1440 {
    @media (max-width: map-get($breakpoints, "1440")) {
        @content;
    }
}

@mixin brakpoints-1366 {
    @media (max-width: map-get($breakpoints, "1366")) {
        @content;
    }
}

@mixin brakpoints-1280 {
    @media (max-width: map-get($breakpoints, "1280")) {
        @content;
    }
}

@mixin brakpoints-360 {
    @media (max-width: map-get($breakpoints, "360")) {
        @content;
    }
}

@mixin brakpoints-375 {
    @media (max-width: map-get($breakpoints, "375")) {
        @content;
    }
}

@mixin brakpoints-414 {
    @media (max-width: map-get($breakpoints, "414")) {
        @content;
    }
}

@mixin brakpoints-430 {
    @media (max-width: map-get($breakpoints, "430")) {
        @content;
    }
}

//common sec start//
.container {
    max-width: 1920px !important;
    padding: 0px 100px !important;
}

@media (max-width: 1300px) {

    .container,
    .container.small {
        padding: 0 60px !important;
    }
}

@media (max-width: 1024px) {

    .container,
    .container.small {
        padding: 0 30px !important;
    }
}

@media (max-width: 991px) {

    .container,
    .container.small {
        padding: 0 20px !important;
    }
}

@media (max-width: 768px) {

    .container,
    .container.small {
        padding: 0 20px !important;
    }
}

@media (max-width: 580px) {

    .container,
    .container.small {
        padding: 0 20px !important;
    }
}

a {
    &:hover {
        text-decoration: none;
    }
}

.primary-btn {
    background: $primary-btn-bg;
    color: $black-color;
    padding: 14px 20px;
    border-radius: 50px;
    border: transparent;
    font-size: 16px;
    font-weight: 400;
    min-width: 130px;
    width: auto;
    font-family: $work-sans-font;
    display: flex;
    align-items: center;
    justify-content: center;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;

    &:hover {
        background: linear-gradient(90deg, #C18C2C 0%, #FCF38A 75%, #C18C2C 100%), linear-gradient(90deg, #C18C2C 0%, #FCF38A 59.37%, #C18C2C 100%);
        box-shadow: 0px 9px 14px rgba(54, 42, 10, 0.18);
        animation: goRight 0.2s ease-in-out forwards;

        @keyframes goRight {
            0% {
                top: 0px;
            }

            100% {
                transform: translateX(0.5px);
            }
        }
    }

    .spinner-border {
        width: 1.7rem;
        height: 1.7rem;
    }

    @include mobile {
        width: 100%;
        font-size: 14px;
        padding: 10px;
        min-height: auto;
        max-width: 96%;
    }

    &.login {
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
    }
}


.send-btn {
    .contact-btn {
        font-family: inherit;
        font-size: 18px;
        background: #1f1e06;
        color: white;
        padding: 0.5em 1em;
        display: flex;
        align-items: center;
        border: none;
        border-radius: 12px;
        overflow: hidden;
        transition: all 0.2s;


        &:hover {
            .svg-wrapper {
                animation: fly-1 0.6s ease-in-out infinite alternate;
            }

            svg {
                transform: translateX(1.2em) rotate(45deg) scale(1.1);
            }

            span {
                transform: translateX(5em);
            }
        }

        &:active {
            transform: scale(0.95);
        }

        span {
            display: block;
            margin-left: 0.3em;
            transition: all 0.3s ease-in-out;
        }

        svg {
            display: block;
            transform-origin: center center;
            transition: transform 0.3s ease-in-out;
        }
    }

    @keyframes fly-1 {
        from {
            transform: translateY(0.1em);
        }

        to {
            transform: translateY(-0.1em);
        }
    }

}

.top-sec {
    padding-top: 106px;
}


.main-h {
    font-size: 64px;
    line-height: 82px;
    letter-spacing: 0.06em;
    color: $primary-txt-color;
    font-family: $wulkan-fonts;
    font-weight: 600;

    @include mobile {
        font-size: 48px;
        line-height: 57px;
        text-align: center;
    }

}


.para-txt {
    font-family: $wulkan-fonts;
    font-size: 20px;
    font-weight: 400;
    color: $black-color;
    line-height: 30px;
}

.p-txt {
    font-size: 18px;
    font-weight: 400;
    font-family: $wulkan-fonts;
    color: $para-txt;
    line-height: 30px;

}


.no-data-found{
  color : #fff;
}

.table{
    thead{
        background-color: #1f1e06;
        th{
            color: $white-color;
        }
    }
}

.react-calendar {
    width: 100% !important;
}

@keyframes drop-in {
    from {
        opacity: 0;
        transform: translateY(100px);
    }

    to {
        opacity: 1;
        transform: translate(0px);
    }
}

.form-control {
    background-color: transparent;
    color: $golden-color;
    border: 1px solid $golden-color;

    &:focus {

        background-color: transparent;
        color: $golden-color;
        border: 1px solid $golden-color;
        box-shadow: none;
    }
}

.ac-login-txt {
    font-size: 16px;
    font-weight: 600;
    color: $golden-color;
    font-family: $wulkan-fonts;

}


.inner-txt {

    font-size: 16px;
    font-weight: 600;
    color: $golden-color;
    text-decoration: underline;
    font-family: $wulkan-fonts;

    &:hover {
        color: $golden-color;
        opacity: 0.8;
        text-decoration: underline;
    }

}

.main-message {
    color: #fff;
}

.red-color{
    color: red;
}
  .loader {
    width:50px;
    height:50px;
    display:inline-block;
    padding:0px;
    opacity:0.5;
    border:3px solid #1f1e06;
    -webkit-animation: loader 1s ease-in-out infinite alternate;
    animation: loader 1s ease-in-out infinite alternate;
 }
 
 .loader:before {
   content: " ";
   position: absolute;
   z-index: -1;
   top: 5px;
   left: 5px;
   right: 5px;
   bottom: 5px;
   border: 3px solid #1f1e06;
 }
 
 .loader:after {
   content: " ";
   position: absolute;
   z-index: -1;
   top: 15px;
   left: 15px;
   right: 15px;
   bottom: 15px;
   border: 3px solid #1f1e06;
 }
 
 @keyframes loader {
    from {transform: rotate(0deg) scale(1,1);border-radius:0px;}
    to {transform: rotate(360deg) scale(0, 0);border-radius:50px;}
 }
 @-webkit-keyframes loader {
    from {-webkit-transform: rotate(0deg) scale(1, 1);border-radius:0px;}
    to {-webkit-transform: rotate(360deg) scale(0,0 );border-radius:50px;}
 }
 
.preloader {
    background-color: #e4c499;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
    margin: 0 auto;
    opacity: 1;

    .pre-inner{
        height: 100%;
    }
  }
 
//common sec end//


// header start
.navbar {
    padding: .5rem 0 !important;

    &.main-nav {
        background: #1f1e06 !important;

        .main-logo {
            width: 90px;
        }

        .navbar-collapse {
            flex-grow: inherit !important;

            @include mobile {
                padding-bottom: 20px;
                height: 100vh;
            }
        }


        .navbar-nav {

            @include mobile{
                align-items: flex-start !important;
            }

            .nav-item {
                .nav-link {
                    color: #e4c499;
                    font-size: 14px;
                    font-weight: 500;
                    margin-right: 2rem !important;
                    font-family: $work-sans-font;
                    text-transform: uppercase;
                    transition: 0.5s ease;
                    -webkit-transition: 0.5s ease;
                    -moz-transition: 0.5s ease;
                    -ms-transition: 0.5s ease;
                    -o-transition: 0.5s ease;

                    &.dropdown-toggle{
                        margin-right: 0rem  !important;
                    }
                }
                


            }


            &:hover {
                .nav-item {
                    .nav-link {
                        color: #e4c499;

                        &:hover {
                            color: #e4c499;
                            box-shadow: 0 2px 0 0 currentcolor;
                            position: relative;
                            top: -0.25rem;

                            @include tablet {
                                top: 0px;
                            }
                        }

                        &.dropdown-toggle {
                            &:hover {
                                box-shadow: none;
                                top: 0rem;
                            }
                        }
                    }
                }


            }
        }

        .header-btn {
            font-size: 14px;
            padding: 14px 20px;
            font-weight: 500;

            @include mobile {
                width: auto;
                min-width: 200px;
                margin-top: 10px;
            }

        }
        .profile-drp{
            @include mobile{
                margin-left: 0px !important;
            }
        }
        .p-name{
            color: $golden-color;
            font-family: $wulkan-fonts;

            @include mobile{
                margin-right: 6px;
            }
        }
    }
}

// header end

//footer start
.footer {
    background: #1f1e06 !important;

    .main-content {
        padding: 80px 0px;
        border-bottom: 1px solid #233148;

        @include mobile {
            padding: 60px 0px;
        }

        .footer-logo {
            width: 200px;

            @include mobile {
                width: 150px;
            }
        }

        .section-t {
            margin-top: 10px;
        }

        .footer-number {
            margin-top: 20px;

            h4 {
                font-size: 20px;
                text-transform: capitalize;
                font-weight: 600;
                color: $golden-color;
                font-family: $work-sans-font;

                @include mobile {
                    font-size: 20px;
                }

            }

            p {
                font-size: 16px;
                color: $golden-color;
                font-family: $work-sans-font;

            }
        }

        .footer-tittle {
            h4 {
                color: $golden-color;
                font-size: 20px;
                font-weight: 600;
                text-transform: uppercase;
                font-family: $wulkan-fonts;
                margin-bottom: 40px;

                @include mobile {
                    margin-bottom: 20px;
                    margin-top: 10px;
                }
            }

            .info1 {

                font-size: 16px;
                color: $golden-color;
                font-family: $work-sans-font;
            }

            ul {
                padding-left: 0px;
                list-style: none;

                li {
                    margin-bottom: 10px;

                    a {
                        font-size: 16px;
                        color: $golden-color;
                        font-family: $work-sans-font;
                    }
                }
            }
        }

        .footer-form {
            position: relative;

            input {
                width: 100%;
                height: 43px;
                padding: 10px 20px;
                border: 0;
                background: #101720;
                color: #fff;

                &::placeholder {
                    color: #9fa6bd;
                    font-weight: 300;
                    font-size: 14px;
                }
            }

            button {
                position: absolute;
                top: 0px;
                right: 0;
                background: none;
                border: 0;
                cursor: pointer;
                padding: 14px 22px;
                background: #0a0f17;
                line-height: 1;
                color: #d19f68;
                font-size: 14px;
                font-weight: 300;
            }
        }

    }

    .footer-social {
        padding: 30px 0px 50px 0px;

        a {
            color: #7c7c7c !important;
            font-size: 14px;
            padding-left: 25px;

            i {
                -webkit-transition: 0.4s;
                -moz-transition: 0.4s;
                -o-transition: 0.4s;
                transition: 0.4s;
                transform: rotateY(0deg);
            }

            &:hover {
                color: $white-color;

                i {
                    transform: rotateY(180deg);
                }
            }
        }
    }
}

//footer end

//home page start
.main-home {
    .main-content {
        padding: 80px 0px;

        @include mobile {
            padding: 60px 0px;
        }

        .main-h {
            margin-bottom: 20px;

            @include mobile {
                font-size: 44px;
            }
        }

        .para-txt {
            margin-bottom: 20px;

        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            animation: drop-in 1200ms ease 500ms backwards;

            @include mobile {
                order: 2;
            }

        }

        .sec-content {
            animation: drop-in 1200ms ease 500ms backwards;

            display: flex;
            justify-content: end;

            @include mobile {
                order: 1;
                margin-bottom: 40px;
            }

            .img-box {
                width: 500px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

//gallary-sec start
.gallary-home {
    background-color: #f8f8f8;

    .main-content {
        padding: 80px 0px;

        @include mobile {
            padding: 60px 0px;
        }

        .main-h {
            color: $golden-color;
            margin-bottom: 40px;
        }

        .gallary-row {
            @include mobile {
                display: none;
            }
        }

        .slider-container {
            .main-h {
                color: $golden-color;
                display: none;

                @include mobile {
                    display: block;
                }
            }
        }

        .gallary-caro-over {
            display: none;

            @include mobile {
                display: block;
            }

            .slick-slide {
                margin: 0 5px !important;
            }
            img {
                width: 200px;
            }

            .content {
                .main-h {
                    color: $golden-color;
                    margin-bottom: 60px;
                }

                img {
                    width: 200px;
                }
            }

            .content-one {
                margin-top: 17px;

                img {
                    width: 200px;
                }

            }

            .content-two {
                margin-top: 40px;

                img {
                    width: 200px;
                }
            }

            .content-3 {

                img {
                    width: 200px;
                }

            }
        }
    }
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
    max-width: 1300px;
    margin: auto;

    &__strip {
        min-height: 100vh;

        &__wrapper {
            flex: 0 0 100%;
            justify-content: flex-end;
            background: transparent;
            border-right: 2px solid #333;
            position: relative;
        }
    }
}


@media (min-width: 500px) {
    .gallery__strip__wrapper {
        flex: 0 0 50%;
    }
}

@media (min-width: 950px) {
    .gallery {
        height: 100vh;

        &__strip {
            &.group-1 {
                animation: 60s move-it ease alternate infinite 5s;
                transform: translateY(2%);
            }

            &.group-3 {
                animation: 70s move-it ease alternate infinite 6s;
                transform: translateY(2%);
            }

            &.group-2 {
                animation: 58s move-it-2 ease alternate infinite 5s;
                transform: translateY(-50%);
            }

            &.group-4 {
                animation: 65s move-it-2 ease alternate infinite 5.5s;
                transform: translateY(-50%);
            }

            &:hover {
                animation-play-state: paused;
            }

            &__wrapper {
                flex: 0 0 25%;
            }
        }
    }
}

.photo {
    position: relative;
    text-align: right;
    padding-bottom: 3rem;

    &__image img {
        width: 90%;
        transform: translateX(30%);
        transition: 1s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
    }

    &__name {
        text-transform: uppercase;
        font-size: 40px;
        letter-spacing: 2px;
        color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: white;
        margin-top: -25px;
        transition: 0.4s ease-in-out 0.4s;
        position: relative;
        width: 100%;
    }

    &:hover {
        .photo__image img {
            transform: translateX(0%);
        }

        .photo__name {
            color: #fff;
        }
    }
}

@keyframes move-it {

    0%,
    90%,
    100% {
        transform: translateY(2%);
    }

    45% {
        transform: translateY(-50%);
    }
}

@keyframes move-it-2 {

    0%,
    90%,
    100% {
        transform: translateY(-50%);
    }

    45% {
        transform: translateY(5%);
    }
}

//gallary-sec end 


//service start
.main-service {
    background-color: #faf4e6;

    .main-content {
        padding: 100px 0px;

        @include mobile {
            padding: 60px 0px;
        }

        .header-sec {
            margin-bottom: 60px;

            @include mobile {
                margin-bottom: 30px;
            }
        }

        .main-h,
        .para-txt {
            color: $golden-color;
        }


        .content {
            .box-item {
                position: relative;
                -webkit-backface-visibility: hidden;
                width: 415px;
                margin-bottom: 35px;
                max-width: 100%;

                .flip-box {
                    -ms-transform-style: preserve-3d;
                    transform-style: preserve-3d;
                    -webkit-transform-style: preserve-3d;
                    perspective: 1000px;
                    -webkit-perspective: 1000px;

                    .flip-box-front,
                    .flip-box-back {
                        background-size: cover;
                        background-position: center;
                        border-radius: 8px;
                        min-height: 475px;
                        -ms-transition: transform 0.7s cubic-bezier(.4, .2, .2, 1);
                        transition: transform 0.7s cubic-bezier(.4, .2, .2, 1);
                        -webkit-transition: transform 0.7s cubic-bezier(.4, .2, .2, 1);
                        -webkit-backface-visibility: hidden;
                        backface-visibility: hidden;
                    }

                    .flip-box-front {
                        -ms-transform: rotateY(0deg);
                        -webkit-transform: rotateY(0deg);
                        transform: rotateY(0deg);
                        -webkit-transform-style: preserve-3d;
                        -ms-transform-style: preserve-3d;
                        transform-style: preserve-3d;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            z-index: 0;
                            background: linear-gradient(270deg, rgb(0 0 0 / 90%) 100%, rgba(217, 217, 217, 0.9) 107.04%);
                            opacity: 0.6;
                            border-radius: 8px;
                            -webkit-border-radius: 8px;
                            -moz-border-radius: 8px;
                            -ms-border-radius: 8px;
                            -o-border-radius: 8px;
                        }
                    }

                    .flip-box-back {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;

                        -ms-transform: rotateY(180deg);
                        -webkit-transform: rotateY(180deg);
                        transform: rotateY(180deg);
                        -webkit-transform-style: preserve-3d;
                        -ms-transform-style: preserve-3d;
                        transform-style: preserve-3d;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            z-index: 0;
                            background: linear-gradient(270deg, rgb(0 0 0 / 90%) 100%, rgba(217, 217, 217, 0.9) 107.04%);
                            opacity: 0.6;
                            border-radius: 8px;
                            -webkit-border-radius: 8px;
                            -moz-border-radius: 8px;
                            -ms-border-radius: 8px;
                            -o-border-radius: 8px;
                        }
                    }

                    &:hover {
                        .flip-box-front {
                            -ms-transform: rotateY(-180deg);
                            -webkit-transform: rotateY(-180deg);
                            transform: rotateY(-180deg);
                            -webkit-transform-style: preserve-3d;
                            -ms-transform-style: preserve-3d;
                            transform-style: preserve-3d;
                        }

                        .flip-box-back {
                            -ms-transform: rotateY(0deg);
                            -webkit-transform: rotateY(0deg);
                            transform: rotateY(0deg);
                            -webkit-transform-style: preserve-3d;
                            -ms-transform-style: preserve-3d;
                            transform-style: preserve-3d;
                        }
                    }

                    .inner {
                        position: absolute;
                        left: 0;
                        width: 100%;
                        padding: 60px 30px;
                        outline: 1px solid transparent;
                        -webkit-perspective: inherit;
                        perspective: inherit;
                        z-index: 2;

                        transform: translateY(-50%) translateZ(60px) scale(.94);
                        -webkit-transform: translateY(-50%) translateZ(60px) scale(.94);
                        -ms-transform: translateY(-50%) translateZ(60px) scale(.94);
                        top: 50%;

                        .para-txt {
                            color: $white-color;
                            font-size: 36px;
                            margin-bottom: 16px;
                            text-transform: uppercase;
                            font-weight: 600;

                            @include mobile {
                                font-size: 30px;
                            }
                        }

                        .p-txt {
                            color: $white-color;
                            font-size: 16px;
                            text-transform: uppercase;
                            font-weight: 600;

                        }
                    }


                }
            }
        }
    }
}

//service end


.appointment-home {
    background-color: #f8f8f8;

    .main-content {
        padding-bottom: 80px;

        @include mobile {
            padding-bottom: 60px;
        }

        .right-div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 40px;
            // align-items: center;

            @include mobile {
                margin-top: 30px;
            }

            .main-h{
                font-size: 48px;
                color: $golden-color;
                text-transform: uppercase;
            }

            .pricing-list {

                ul {
                    padding-left: 0px;
                    list-style: none;
                    li {
                        font-family: "Oswald", sans-serif;
                        text-transform: uppercase;
                        font-size: 15px;
                        font-weight: 500;
                        color: #010101;
                        margin-bottom: 18px;

                        span {
                            color: #d19f68 !important;
                            font-weight: 400;
                            font-size: 16px;
                          }
                    }
                }
            }
        }
    }

}

//feedback start
.feedback {
    background-color: #faf4e6;

    .main-content {
        padding: 80px 0px;
    }

}

//feedback end


//home page end


//contact us start
.get-in-touch {
    background-color: #f8f8f8;

    .main-content {
        padding: 80px 0px 80px 0px;

        @include mobile {
            padding: 60px 0px 50px 0px;
        }

        .card {
            padding: 70px;
            background-color: #1f1e06;
            border-radius: 30px;
            border: 0px;

            @include tablet {
                padding: 50px 40px;
            }

            @include mobile {
                padding: 60px 20px;
            }

            .first-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                @include mobile {
                    align-items: center;
                }

                .main-h {
                    font-weight: 800;
                    line-height: 79px;
                    letter-spacing: 0.08em;
                    color: $golden-color;
                    margin-bottom: 10px;
                    // min-width: 226px;`
                    text-align: start;

                    @include mobile {
                        line-height: 55px;
                        text-align: center;
                        letter-spacing: 0em;
                        margin-bottom: 20px;
                    }
                }

                .info {

                    .para-txt {
                        color: $golden-color;
                        font-family: $work-sans-font;
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 20px;

                    }
                }

                .social-sec {
                    .p-txt {
                        font-size: 20px;
                        line-height: 20px;
                        letter-spacing: 0.08em;
                        color: $golden-color;
                        margin-bottom: 15px;

                        @include mobile {
                            text-align: center;
                        }
                    }

                    .social-icons {

                        .icon-ul {
                            width: 100%;
                            text-align: center;
                            display: flex;
                            padding-left: 0px;

                            // transform: translate(0,-50%);

                            li {
                                list-style: none;
                                display: inline-block;

                                &:nth-child(1) {
                                    .f-link {
                                        &::before {
                                            background: #3b5999;
                                        }
                                    }
                                }

                                &:nth-child(2) {
                                    .f-link {
                                        &::before {
                                            background: #dd4b39;
                                        }
                                    }
                                }


                                .f-link {
                                    position: relative;
                                    text-decoration: none;
                                    color: #515151;
                                    background: #fff;
                                    display: inline-block;
                                    width: 40px;
                                    height: 40px;
                                    text-align: center;
                                    line-height: 100px;
                                    border-radius: 50%;
                                    font-size: 20px;
                                    z-index: 1;
                                    overflow: hidden;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    &:hover {
                                        color: #fff;

                                        &::before {
                                            transform: translateX(0) scale(.9);
                                        }

                                        &::after {
                                            left: 50%;
                                        }
                                    }

                                    &::before {
                                        content: '';
                                        position: absolute;
                                        top: 0%;
                                        left: 0%;
                                        width: 100%;
                                        height: 100%;
                                        transform: translateX(-100px) scale(0);
                                        border-radius: 50%;
                                        z-index: -2;
                                        transition: .5s;
                                    }

                                    &::after {
                                        content: '';
                                        position: absolute;
                                        top: 0;
                                        left: 100%;
                                        width: 100%;
                                        height: 100%;
                                        background: rgba(255, 255, 255, .5);
                                        z-index: -1;
                                        transition: .5s;
                                        transition-delay: .5s;
                                    }
                                }
                            }
                        }

                        .icon-box {
                            border: 1px solid #FFFFFF;
                            border-radius: 10px;
                            padding: 3px 10px;
                            cursor: pointer;

                            &:hover {
                                background-color: $white-color;
                                border: 1px solid #52684B;


                                .f-link {
                                    color: #52684B;
                                }
                            }

                            .f-link {
                                font-size: 20px;
                                color: $white-color;
                            }
                        }
                    }
                }
            }

            .mobile-div {

                @include mobile {
                    margin-top: 20px;
                    justify-content: center !important;
                }
            }

            .content-card {
                width: 395px;
                min-height: 340px;
                background: $white-color;
                box-shadow: 0px 20px 21px rgba(0, 0, 0, 0.25);
                border-radius: 20px;
                padding: 40px;
                position: relative;
                z-index: 11;

                @include tablet {
                    width: auto;
                    height: auto;
                    transform: translateX(135px);
                }

                @include mobile {
                    width: auto;
                    height: auto;
                    transform: translateX(0px);
                }

                .send-btn {
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                }

                .form-group {
                    position: relative;
                    margin-bottom: 1.5rem;

                    input {
                        padding-left: 0px;
                    }
                }

                .form-control-placeholder {
                    position: absolute;
                    top: 0;
                    padding: 7px 0 0 0px;
                    transition: all 200ms;
                    opacity: 0.5;
                    color: #2D2D2D !important;
                }

                .form-control:focus+.form-control-placeholder,
                .form-control:valid+.form-control-placeholder {
                    transform: translate3d(0, -100%, 0);
                    opacity: 1;
                    top: 10px;
                }

                .form-control {
                    border: 0;
                    outline: 0;
                    border-bottom: 2px solid #B7B7B7;
                    border-radius: 0px;
                    font-size: 16px;
                    color: #2D2D2D;
                    background-color: transparent;
                    padding: .375rem 0;

                    &:focus {
                        border: 0;
                        color: #2D2D2D;
                        background-color: transparent;
                        border-color: #fff;
                        outline: 0;
                        border-bottom: 2px solid #B7B7B7;
                        box-shadow: 0 0 0 0.2rem transparent;
                    }

                }
            }

            .second-content {
                .row {
                    // @include large-tablet {
                    //     justify-content: start !important;
                    // }
                }






            }
        }
    }
}

//contact end

//about start
.about {
    .main-content {
        padding: 80px 0px;

        .content {
            display: flex;
            flex-direction: column;
            justify-content: start;
            animation: drop-in 1200ms ease 500ms backwards;

            @include mobile{
                margin-bottom: 40px;
            }
            .p-txt{
                margin-right: 30px;

                @include mobile{
                    margin-right: 0px;
                }
            }

        }

        .sec-content {
            animation: drop-in 1200ms ease 500ms backwards;

            .img-box {
                width: fit-content;
                height: auto;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

//about end


//book-appointment start
.book-appointment {
    background-color: #f8f8f8;

    .main-content {
        padding: 80px 0px;

        .card {
            padding: 70px;
            background-color: #1f1e06;
            border-radius: 30px;
            border: 0px;

            @include tablet {
                padding: 50px 40px;
            }

            @include mobile {
                padding: 60px 20px;
            }

            .main-h {
                color: $golden-color;
            }
        }
    }

    .book_slot {
        display: flex;
        align-items: center;
        width: 100%;
        float: left;
        position: relative;
        box-shadow: 0px 2px 10px #3d333380;
        border-radius: 5px;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 4px;
        border: 1px solid $golden-color;

        ul {
            margin-top: 0;
            padding-left: 10px;
            margin-bottom: 0;

            li {
                float: left;
                padding-right: 24px;
                border-right: 1px solid #fff;
                margin-right: 12px;
                list-style: none;

                span {
                    color: #ffffff;
                    font-size: 14px;
                    // font-family: "poppinssemibold";
                    min-width: 67px;
                }

                p {
                    padding-top: 5px;
                    color: #fff;
                    font-size: 14px;
                }
            }
        }
    }
}

//book-appointment end

//login start
.login {
    background-color: #f8f8f8;

    .main-content {
        padding: 80px 0px;

        @include mobile {
            padding: 60px 0px;

        }

        .card {
            padding: 70px;
            background-color: #1f1e06;
            border: 0px;

            @include tablet {
                padding: 50px 40px;
            }

            @include mobile {
                padding: 60px 20px;
            }

            .main-h {
                color: $golden-color;

                @include mobile {
                    font-size: 48px;
                }
            }
        }
    }

    &.forgot-pass {
        .main-content {
            .card {
                .main-h {
                    font-size: 58px;

                    @include mobile {
                        font-size: 48px;
                    }
                }
            }
        }
    }
}

//login end


//client story
.client-story {
    // background: $new-bg;

    .main-content {
        padding: 80px 0px;

        @include tablet {
            padding: 80px 0px;
        }

        @include mobile {
            padding: 60px 20px 20px 20px;
        }

        .one {
            position: relative;

        }

        .right-child {
            @include mobile {
                display: flex;
                flex-direction: column;
                align-items: center;

            }

            &.posFix {
                position: fixed;
                top: 25px;
                max-width: 20%;

                @include tablet {
                    top: 94px;
                }
            }

            &.posAbs {
                position: absolute;
                bottom: 25px;

                @include tablet {
                    bottom: 94px;

                }
            }

            .main-h {
                line-height: 82px;
                letter-spacing: 0.06em;
                margin-bottom: 20px;
                text-align: start;
                font-family: $wulkan-fonts;
                font-weight: 600;

                @include mobile {
                    line-height: 52px;
                    text-align: center;
                }
            }

            .p-txt {
                font-size: 20px;
                line-height: 20px;
                font-weight: 600;
                margin-bottom: 40px;

                .doubt {
                    font-weight: 500;
                    text-decoration: underline;
                }
            }


        }

        .second-content {
            .content {
                @include tablet {
                    margin-left: 80px;
                }

                @include mobile {
                    margin-left: 0px;
                }

                &.first {
                    margin-top: 80px;

                    @include tablet {
                        margin-top: 0px;
                    }

                    @include mobile {
                        margin-top: 0px;
                        margin-left: 0px;
                    }
                }


                .box {
                    background: $white-color;
                    border-radius: 12px;
                    padding: 40px 20px;
                    margin-bottom: 40px;

                    .p-txt {
                        font-size: 16px;
                        line-height: 22px;
                        letter-spacing: 0.06em;
                        // color: $sec-txt-color;

                        &.name {
                            font-size: 18px;
                            line-height: 18px;
                            color: $black-color;
                            margin-top: 20px;
                            font-family: $wulkan-fonts;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}
//client story


.prev-btn{
    cursor: pointer;
  }
  .next-btn{
    cursor: pointer;
  
  }
  .prev-btn:disabled{
    cursor: not-allowed;
  }
  
  .page-item.disabled .page-link {
    cursor: not-allowed !important;
    pointer-events: all;
  
  }
