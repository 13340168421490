@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {
  font-family: "Wulkan Display";
  src: url("/src/assets/Font/wulkan/WulkanDisplayBold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Wulkan Display";
  src: url("/src/assets/Font/wulkan/WulkanDisplayBlack.woff2") format("woff2"), url("/src/assets/Font/wulkan/WulkanDisplayBlack.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Wulkan Display";
  src: url("/src/assets/Font/wulkan/WulkanDisplayLight.woff2") format("woff2"), url("/src/assets/Font/wulkan/WulkanDisplayLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Wulkan Display";
  src: url("/src/assets/Font/wulkan/WulkanDisplaySemiBold.woff2") format("woff2"), url("/src/assets/Font/wulkan/WulkanDisplaySemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Wulkan Display";
  src: url("/src/assets/Font/wulkan/WulkanDisplayMedium.woff2") format("woff2"), url("/src/assets/Font/wulkan/WulkanDisplayMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
body {
  background: #e4c499 !important;
  overflow-x: hidden;
  max-width: 1920px;
  margin: auto !important;
}

html {
  overflow-x: hidden;
}

/* Standard breakpoints */
.container {
  max-width: 1920px !important;
  padding: 0px 100px !important;
}

@media (max-width: 1300px) {
  .container,
  .container.small {
    padding: 0 60px !important;
  }
}
@media (max-width: 1024px) {
  .container,
  .container.small {
    padding: 0 30px !important;
  }
}
@media (max-width: 991px) {
  .container,
  .container.small {
    padding: 0 20px !important;
  }
}
@media (max-width: 768px) {
  .container,
  .container.small {
    padding: 0 20px !important;
  }
}
@media (max-width: 580px) {
  .container,
  .container.small {
    padding: 0 20px !important;
  }
}
a:hover {
  text-decoration: none;
}

.primary-btn {
  background: linear-gradient(90deg, #C18C2C 0%, #FCF38A 50.52%, #C18C2C 100%);
  color: #000;
  padding: 14px 20px;
  border-radius: 50px;
  border: transparent;
  font-size: 16px;
  font-weight: 400;
  min-width: 130px;
  width: auto;
  font-family: "Work Sans", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;
}
.primary-btn:hover {
  background: linear-gradient(90deg, #C18C2C 0%, #FCF38A 75%, #C18C2C 100%), linear-gradient(90deg, #C18C2C 0%, #FCF38A 59.37%, #C18C2C 100%);
  box-shadow: 0px 9px 14px rgba(54, 42, 10, 0.18);
  animation: goRight 0.2s ease-in-out forwards;
}
@keyframes goRight {
  0% {
    top: 0px;
  }
  100% {
    transform: translateX(0.5px);
  }
}
.primary-btn .spinner-border {
  width: 1.7rem;
  height: 1.7rem;
}
@media (max-width: 526px) {
  .primary-btn {
    width: 100%;
    font-size: 14px;
    padding: 10px;
    min-height: auto;
    max-width: 96%;
  }
}
.primary-btn.login {
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.send-btn .contact-btn {
  font-family: inherit;
  font-size: 18px;
  background: #1f1e06;
  color: white;
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.2s;
}
.send-btn .contact-btn:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}
.send-btn .contact-btn:hover svg {
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}
.send-btn .contact-btn:hover span {
  transform: translateX(5em);
}
.send-btn .contact-btn:active {
  transform: scale(0.95);
}
.send-btn .contact-btn span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}
.send-btn .contact-btn svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}
@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }
  to {
    transform: translateY(-0.1em);
  }
}

.top-sec {
  padding-top: 106px;
}

.main-h {
  font-size: 64px;
  line-height: 82px;
  letter-spacing: 0.06em;
  color: #1C1717;
  font-family: "Wulkan Display";
  font-weight: 600;
}
@media (max-width: 526px) {
  .main-h {
    font-size: 48px;
    line-height: 57px;
    text-align: center;
  }
}

.para-txt {
  font-family: "Wulkan Display";
  font-size: 20px;
  font-weight: 400;
  color: #000;
  line-height: 30px;
}

.p-txt {
  font-size: 18px;
  font-weight: 400;
  font-family: "Wulkan Display";
  color: #1e1e1e;
  line-height: 30px;
}

.no-data-found {
  color: #fff;
}

.table thead {
  background-color: #1f1e06;
}
.table thead th {
  color: #fff;
}

.react-calendar {
  width: 100% !important;
}

@keyframes drop-in {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translate(0px);
  }
}
.form-control {
  background-color: transparent;
  color: #e4c499;
  border: 1px solid #e4c499;
}
.form-control:focus {
  background-color: transparent;
  color: #e4c499;
  border: 1px solid #e4c499;
  box-shadow: none;
}

.ac-login-txt {
  font-size: 16px;
  font-weight: 600;
  color: #e4c499;
  font-family: "Wulkan Display";
}

.inner-txt {
  font-size: 16px;
  font-weight: 600;
  color: #e4c499;
  text-decoration: underline;
  font-family: "Wulkan Display";
}
.inner-txt:hover {
  color: #e4c499;
  opacity: 0.8;
  text-decoration: underline;
}

.main-message {
  color: #fff;
}

.red-color {
  color: red;
}

.loader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  opacity: 0.5;
  border: 3px solid #1f1e06;
  animation: loader 1s ease-in-out infinite alternate;
}

.loader:before {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border: 3px solid #1f1e06;
}

.loader:after {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: 3px solid #1f1e06;
}

@keyframes loader {
  from {
    transform: rotate(0deg) scale(1, 1);
    border-radius: 0px;
  }
  to {
    transform: rotate(360deg) scale(0, 0);
    border-radius: 50px;
  }
}
.preloader {
  background-color: #e4c499;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  transition: 0.6s;
  margin: 0 auto;
  opacity: 1;
}
.preloader .pre-inner {
  height: 100%;
}

.navbar {
  padding: 0.5rem 0 !important;
}
.navbar.main-nav {
  background: #1f1e06 !important;
}
.navbar.main-nav .main-logo {
  width: 90px;
}
.navbar.main-nav .navbar-collapse {
  flex-grow: inherit !important;
}
@media (max-width: 526px) {
  .navbar.main-nav .navbar-collapse {
    padding-bottom: 20px;
    height: 100vh;
  }
}
@media (max-width: 526px) {
  .navbar.main-nav .navbar-nav {
    align-items: flex-start !important;
  }
}
.navbar.main-nav .navbar-nav .nav-item .nav-link {
  color: #e4c499;
  font-size: 14px;
  font-weight: 500;
  margin-right: 2rem !important;
  font-family: "Work Sans", sans-serif;
  text-transform: uppercase;
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  -o-transition: 0.5s ease;
}
.navbar.main-nav .navbar-nav .nav-item .nav-link.dropdown-toggle {
  margin-right: 0rem !important;
}
.navbar.main-nav .navbar-nav:hover .nav-item .nav-link {
  color: #e4c499;
}
.navbar.main-nav .navbar-nav:hover .nav-item .nav-link:hover {
  color: #e4c499;
  box-shadow: 0 2px 0 0 currentcolor;
  position: relative;
  top: -0.25rem;
}
@media (max-width: 912px) {
  .navbar.main-nav .navbar-nav:hover .nav-item .nav-link:hover {
    top: 0px;
  }
}
.navbar.main-nav .navbar-nav:hover .nav-item .nav-link.dropdown-toggle:hover {
  box-shadow: none;
  top: 0rem;
}
.navbar.main-nav .header-btn {
  font-size: 14px;
  padding: 14px 20px;
  font-weight: 500;
}
@media (max-width: 526px) {
  .navbar.main-nav .header-btn {
    width: auto;
    min-width: 200px;
    margin-top: 10px;
  }
}
@media (max-width: 526px) {
  .navbar.main-nav .profile-drp {
    margin-left: 0px !important;
  }
}
.navbar.main-nav .p-name {
  color: #e4c499;
  font-family: "Wulkan Display";
}
@media (max-width: 526px) {
  .navbar.main-nav .p-name {
    margin-right: 6px;
  }
}

.footer {
  background: #1f1e06 !important;
}
.footer .main-content {
  padding: 80px 0px;
  border-bottom: 1px solid #233148;
}
@media (max-width: 526px) {
  .footer .main-content {
    padding: 60px 0px;
  }
}
.footer .main-content .footer-logo {
  width: 200px;
}
@media (max-width: 526px) {
  .footer .main-content .footer-logo {
    width: 150px;
  }
}
.footer .main-content .section-t {
  margin-top: 10px;
}
.footer .main-content .footer-number {
  margin-top: 20px;
}
.footer .main-content .footer-number h4 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  color: #e4c499;
  font-family: "Work Sans", sans-serif;
}
@media (max-width: 526px) {
  .footer .main-content .footer-number h4 {
    font-size: 20px;
  }
}
.footer .main-content .footer-number p {
  font-size: 16px;
  color: #e4c499;
  font-family: "Work Sans", sans-serif;
}
.footer .main-content .footer-tittle h4 {
  color: #e4c499;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Wulkan Display";
  margin-bottom: 40px;
}
@media (max-width: 526px) {
  .footer .main-content .footer-tittle h4 {
    margin-bottom: 20px;
    margin-top: 10px;
  }
}
.footer .main-content .footer-tittle .info1 {
  font-size: 16px;
  color: #e4c499;
  font-family: "Work Sans", sans-serif;
}
.footer .main-content .footer-tittle ul {
  padding-left: 0px;
  list-style: none;
}
.footer .main-content .footer-tittle ul li {
  margin-bottom: 10px;
}
.footer .main-content .footer-tittle ul li a {
  font-size: 16px;
  color: #e4c499;
  font-family: "Work Sans", sans-serif;
}
.footer .main-content .footer-form {
  position: relative;
}
.footer .main-content .footer-form input {
  width: 100%;
  height: 43px;
  padding: 10px 20px;
  border: 0;
  background: #101720;
  color: #fff;
}
.footer .main-content .footer-form input::-moz-placeholder {
  color: #9fa6bd;
  font-weight: 300;
  font-size: 14px;
}
.footer .main-content .footer-form input::placeholder {
  color: #9fa6bd;
  font-weight: 300;
  font-size: 14px;
}
.footer .main-content .footer-form button {
  position: absolute;
  top: 0px;
  right: 0;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 14px 22px;
  background: #0a0f17;
  line-height: 1;
  color: #d19f68;
  font-size: 14px;
  font-weight: 300;
}
.footer .footer-social {
  padding: 30px 0px 50px 0px;
}
.footer .footer-social a {
  color: #7c7c7c !important;
  font-size: 14px;
  padding-left: 25px;
}
.footer .footer-social a i {
  transition: 0.4s;
  transform: rotateY(0deg);
}
.footer .footer-social a:hover {
  color: #fff;
}
.footer .footer-social a:hover i {
  transform: rotateY(180deg);
}

.main-home .main-content {
  padding: 80px 0px;
}
@media (max-width: 526px) {
  .main-home .main-content {
    padding: 60px 0px;
  }
}
.main-home .main-content .main-h {
  margin-bottom: 20px;
}
@media (max-width: 526px) {
  .main-home .main-content .main-h {
    font-size: 44px;
  }
}
.main-home .main-content .para-txt {
  margin-bottom: 20px;
}
.main-home .main-content .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: drop-in 1200ms ease 500ms backwards;
}
@media (max-width: 526px) {
  .main-home .main-content .content {
    order: 2;
  }
}
.main-home .main-content .sec-content {
  animation: drop-in 1200ms ease 500ms backwards;
  display: flex;
  justify-content: end;
}
@media (max-width: 526px) {
  .main-home .main-content .sec-content {
    order: 1;
    margin-bottom: 40px;
  }
}
.main-home .main-content .sec-content .img-box {
  width: 500px;
}
.main-home .main-content .sec-content .img-box img {
  width: 100%;
  height: 100%;
}

.gallary-home {
  background-color: #f8f8f8;
}
.gallary-home .main-content {
  padding: 80px 0px;
}
@media (max-width: 526px) {
  .gallary-home .main-content {
    padding: 60px 0px;
  }
}
.gallary-home .main-content .main-h {
  color: #e4c499;
  margin-bottom: 40px;
}
@media (max-width: 526px) {
  .gallary-home .main-content .gallary-row {
    display: none;
  }
}
.gallary-home .main-content .slider-container .main-h {
  color: #e4c499;
  display: none;
}
@media (max-width: 526px) {
  .gallary-home .main-content .slider-container .main-h {
    display: block;
  }
}
.gallary-home .main-content .gallary-caro-over {
  display: none;
}
@media (max-width: 526px) {
  .gallary-home .main-content .gallary-caro-over {
    display: block;
  }
}
.gallary-home .main-content .gallary-caro-over .slick-slide {
  margin: 0 5px !important;
}
.gallary-home .main-content .gallary-caro-over img {
  width: 200px;
}
.gallary-home .main-content .gallary-caro-over .content .main-h {
  color: #e4c499;
  margin-bottom: 60px;
}
.gallary-home .main-content .gallary-caro-over .content img {
  width: 200px;
}
.gallary-home .main-content .gallary-caro-over .content-one {
  margin-top: 17px;
}
.gallary-home .main-content .gallary-caro-over .content-one img {
  width: 200px;
}
.gallary-home .main-content .gallary-caro-over .content-two {
  margin-top: 40px;
}
.gallary-home .main-content .gallary-caro-over .content-two img {
  width: 200px;
}
.gallary-home .main-content .gallary-caro-over .content-3 img {
  width: 200px;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  max-width: 1300px;
  margin: auto;
}
.gallery__strip {
  min-height: 100vh;
}
.gallery__strip__wrapper {
  flex: 0 0 100%;
  justify-content: flex-end;
  background: transparent;
  border-right: 2px solid #333;
  position: relative;
}

@media (min-width: 500px) {
  .gallery__strip__wrapper {
    flex: 0 0 50%;
  }
}
@media (min-width: 950px) {
  .gallery {
    height: 100vh;
  }
  .gallery__strip.group-1 {
    animation: 60s move-it ease alternate infinite 5s;
    transform: translateY(2%);
  }
  .gallery__strip.group-3 {
    animation: 70s move-it ease alternate infinite 6s;
    transform: translateY(2%);
  }
  .gallery__strip.group-2 {
    animation: 58s move-it-2 ease alternate infinite 5s;
    transform: translateY(-50%);
  }
  .gallery__strip.group-4 {
    animation: 65s move-it-2 ease alternate infinite 5.5s;
    transform: translateY(-50%);
  }
  .gallery__strip:hover {
    animation-play-state: paused;
  }
  .gallery__strip__wrapper {
    flex: 0 0 25%;
  }
}
.photo {
  position: relative;
  text-align: right;
  padding-bottom: 3rem;
}
.photo__image img {
  width: 90%;
  transform: translateX(30%);
  transition: 1s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
}
.photo__name {
  text-transform: uppercase;
  font-size: 40px;
  letter-spacing: 2px;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  margin-top: -25px;
  transition: 0.4s ease-in-out 0.4s;
  position: relative;
  width: 100%;
}
.photo:hover .photo__image img {
  transform: translateX(0%);
}
.photo:hover .photo__name {
  color: #fff;
}

@keyframes move-it {
  0%, 90%, 100% {
    transform: translateY(2%);
  }
  45% {
    transform: translateY(-50%);
  }
}
@keyframes move-it-2 {
  0%, 90%, 100% {
    transform: translateY(-50%);
  }
  45% {
    transform: translateY(5%);
  }
}
.main-service {
  background-color: #faf4e6;
}
.main-service .main-content {
  padding: 100px 0px;
}
@media (max-width: 526px) {
  .main-service .main-content {
    padding: 60px 0px;
  }
}
.main-service .main-content .header-sec {
  margin-bottom: 60px;
}
@media (max-width: 526px) {
  .main-service .main-content .header-sec {
    margin-bottom: 30px;
  }
}
.main-service .main-content .main-h,
.main-service .main-content .para-txt {
  color: #e4c499;
}
.main-service .main-content .content .box-item {
  position: relative;
  -webkit-backface-visibility: hidden;
  width: 415px;
  margin-bottom: 35px;
  max-width: 100%;
}
.main-service .main-content .content .box-item .flip-box {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  perspective: 1000px;
  -webkit-perspective: 1000px;
}
.main-service .main-content .content .box-item .flip-box .flip-box-front,
.main-service .main-content .content .box-item .flip-box .flip-box-back {
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  min-height: 475px;
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  backface-visibility: hidden;
}
.main-service .main-content .content .box-item .flip-box .flip-box-front {
  transform: rotateY(0deg);
  transform-style: preserve-3d;
}
.main-service .main-content .content .box-item .flip-box .flip-box-front::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.9) 100%, rgba(217, 217, 217, 0.9) 107.04%);
  opacity: 0.6;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.main-service .main-content .content .box-item .flip-box .flip-box-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: rotateY(180deg);
  transform-style: preserve-3d;
}
.main-service .main-content .content .box-item .flip-box .flip-box-back::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.9) 100%, rgba(217, 217, 217, 0.9) 107.04%);
  opacity: 0.6;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.main-service .main-content .content .box-item .flip-box:hover .flip-box-front {
  transform: rotateY(-180deg);
  transform-style: preserve-3d;
}
.main-service .main-content .content .box-item .flip-box:hover .flip-box-back {
  transform: rotateY(0deg);
  transform-style: preserve-3d;
}
.main-service .main-content .content .box-item .flip-box .inner {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 60px 30px;
  outline: 1px solid transparent;
  perspective: inherit;
  z-index: 2;
  transform: translateY(-50%) translateZ(60px) scale(0.94);
  -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
  -ms-transform: translateY(-50%) translateZ(60px) scale(0.94);
  top: 50%;
}
.main-service .main-content .content .box-item .flip-box .inner .para-txt {
  color: #fff;
  font-size: 36px;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-weight: 600;
}
@media (max-width: 526px) {
  .main-service .main-content .content .box-item .flip-box .inner .para-txt {
    font-size: 30px;
  }
}
.main-service .main-content .content .box-item .flip-box .inner .p-txt {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}

.appointment-home {
  background-color: #f8f8f8;
}
.appointment-home .main-content {
  padding-bottom: 80px;
}
@media (max-width: 526px) {
  .appointment-home .main-content {
    padding-bottom: 60px;
  }
}
.appointment-home .main-content .right-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
}
@media (max-width: 526px) {
  .appointment-home .main-content .right-div {
    margin-top: 30px;
  }
}
.appointment-home .main-content .right-div .main-h {
  font-size: 48px;
  color: #e4c499;
  text-transform: uppercase;
}
.appointment-home .main-content .right-div .pricing-list ul {
  padding-left: 0px;
  list-style: none;
}
.appointment-home .main-content .right-div .pricing-list ul li {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  color: #010101;
  margin-bottom: 18px;
}
.appointment-home .main-content .right-div .pricing-list ul li span {
  color: #d19f68 !important;
  font-weight: 400;
  font-size: 16px;
}

.feedback {
  background-color: #faf4e6;
}
.feedback .main-content {
  padding: 80px 0px;
}

.get-in-touch {
  background-color: #f8f8f8;
}
.get-in-touch .main-content {
  padding: 80px 0px 80px 0px;
}
@media (max-width: 526px) {
  .get-in-touch .main-content {
    padding: 60px 0px 50px 0px;
  }
}
.get-in-touch .main-content .card {
  padding: 70px;
  background-color: #1f1e06;
  border-radius: 30px;
  border: 0px;
}
@media (max-width: 912px) {
  .get-in-touch .main-content .card {
    padding: 50px 40px;
  }
}
@media (max-width: 526px) {
  .get-in-touch .main-content .card {
    padding: 60px 20px;
  }
}
.get-in-touch .main-content .card .first-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 526px) {
  .get-in-touch .main-content .card .first-content {
    align-items: center;
  }
}
.get-in-touch .main-content .card .first-content .main-h {
  font-weight: 800;
  line-height: 79px;
  letter-spacing: 0.08em;
  color: #e4c499;
  margin-bottom: 10px;
  text-align: start;
}
@media (max-width: 526px) {
  .get-in-touch .main-content .card .first-content .main-h {
    line-height: 55px;
    text-align: center;
    letter-spacing: 0em;
    margin-bottom: 20px;
  }
}
.get-in-touch .main-content .card .first-content .info .para-txt {
  color: #e4c499;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}
.get-in-touch .main-content .card .first-content .social-sec .p-txt {
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.08em;
  color: #e4c499;
  margin-bottom: 15px;
}
@media (max-width: 526px) {
  .get-in-touch .main-content .card .first-content .social-sec .p-txt {
    text-align: center;
  }
}
.get-in-touch .main-content .card .first-content .social-sec .social-icons .icon-ul {
  width: 100%;
  text-align: center;
  display: flex;
  padding-left: 0px;
}
.get-in-touch .main-content .card .first-content .social-sec .social-icons .icon-ul li {
  list-style: none;
  display: inline-block;
}
.get-in-touch .main-content .card .first-content .social-sec .social-icons .icon-ul li:nth-child(1) .f-link::before {
  background: #3b5999;
}
.get-in-touch .main-content .card .first-content .social-sec .social-icons .icon-ul li:nth-child(2) .f-link::before {
  background: #dd4b39;
}
.get-in-touch .main-content .card .first-content .social-sec .social-icons .icon-ul li .f-link {
  position: relative;
  text-decoration: none;
  color: #515151;
  background: #fff;
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 100px;
  border-radius: 50%;
  font-size: 20px;
  z-index: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.get-in-touch .main-content .card .first-content .social-sec .social-icons .icon-ul li .f-link:hover {
  color: #fff;
}
.get-in-touch .main-content .card .first-content .social-sec .social-icons .icon-ul li .f-link:hover::before {
  transform: translateX(0) scale(0.9);
}
.get-in-touch .main-content .card .first-content .social-sec .social-icons .icon-ul li .f-link:hover::after {
  left: 50%;
}
.get-in-touch .main-content .card .first-content .social-sec .social-icons .icon-ul li .f-link::before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  transform: translateX(-100px) scale(0);
  border-radius: 50%;
  z-index: -2;
  transition: 0.5s;
}
.get-in-touch .main-content .card .first-content .social-sec .social-icons .icon-ul li .f-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: -1;
  transition: 0.5s;
  transition-delay: 0.5s;
}
.get-in-touch .main-content .card .first-content .social-sec .social-icons .icon-box {
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  padding: 3px 10px;
  cursor: pointer;
}
.get-in-touch .main-content .card .first-content .social-sec .social-icons .icon-box:hover {
  background-color: #fff;
  border: 1px solid #52684B;
}
.get-in-touch .main-content .card .first-content .social-sec .social-icons .icon-box:hover .f-link {
  color: #52684B;
}
.get-in-touch .main-content .card .first-content .social-sec .social-icons .icon-box .f-link {
  font-size: 20px;
  color: #fff;
}
@media (max-width: 526px) {
  .get-in-touch .main-content .card .mobile-div {
    margin-top: 20px;
    justify-content: center !important;
  }
}
.get-in-touch .main-content .card .content-card {
  width: 395px;
  min-height: 340px;
  background: #fff;
  box-shadow: 0px 20px 21px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 40px;
  position: relative;
  z-index: 11;
}
@media (max-width: 912px) {
  .get-in-touch .main-content .card .content-card {
    width: auto;
    height: auto;
    transform: translateX(135px);
  }
}
@media (max-width: 526px) {
  .get-in-touch .main-content .card .content-card {
    width: auto;
    height: auto;
    transform: translateX(0px);
  }
}
.get-in-touch .main-content .card .content-card .send-btn {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.get-in-touch .main-content .card .content-card .form-group {
  position: relative;
  margin-bottom: 1.5rem;
}
.get-in-touch .main-content .card .content-card .form-group input {
  padding-left: 0px;
}
.get-in-touch .main-content .card .content-card .form-control-placeholder {
  position: absolute;
  top: 0;
  padding: 7px 0 0 0px;
  transition: all 200ms;
  opacity: 0.5;
  color: #2D2D2D !important;
}
.get-in-touch .main-content .card .content-card .form-control:focus + .form-control-placeholder,
.get-in-touch .main-content .card .content-card .form-control:valid + .form-control-placeholder {
  transform: translate3d(0, -100%, 0);
  opacity: 1;
  top: 10px;
}
.get-in-touch .main-content .card .content-card .form-control {
  border: 0;
  outline: 0;
  border-bottom: 2px solid #B7B7B7;
  border-radius: 0px;
  font-size: 16px;
  color: #2D2D2D;
  background-color: transparent;
  padding: 0.375rem 0;
}
.get-in-touch .main-content .card .content-card .form-control:focus {
  border: 0;
  color: #2D2D2D;
  background-color: transparent;
  border-color: #fff;
  outline: 0;
  border-bottom: 2px solid #B7B7B7;
  box-shadow: 0 0 0 0.2rem transparent;
}
.about .main-content {
  padding: 80px 0px;
}
.about .main-content .content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  animation: drop-in 1200ms ease 500ms backwards;
}
@media (max-width: 526px) {
  .about .main-content .content {
    margin-bottom: 40px;
  }
}
.about .main-content .content .p-txt {
  margin-right: 30px;
}
@media (max-width: 526px) {
  .about .main-content .content .p-txt {
    margin-right: 0px;
  }
}
.about .main-content .sec-content {
  animation: drop-in 1200ms ease 500ms backwards;
}
.about .main-content .sec-content .img-box {
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
}
.about .main-content .sec-content .img-box img {
  width: 100%;
  height: 100%;
}

.book-appointment {
  background-color: #f8f8f8;
}
.book-appointment .main-content {
  padding: 80px 0px;
}
.book-appointment .main-content .card {
  padding: 70px;
  background-color: #1f1e06;
  border-radius: 30px;
  border: 0px;
}
@media (max-width: 912px) {
  .book-appointment .main-content .card {
    padding: 50px 40px;
  }
}
@media (max-width: 526px) {
  .book-appointment .main-content .card {
    padding: 60px 20px;
  }
}
.book-appointment .main-content .card .main-h {
  color: #e4c499;
}
.book-appointment .book_slot {
  display: flex;
  align-items: center;
  width: 100%;
  float: left;
  position: relative;
  box-shadow: 0px 2px 10px rgba(61, 51, 51, 0.5019607843);
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 4px;
  border: 1px solid #e4c499;
}
.book-appointment .book_slot ul {
  margin-top: 0;
  padding-left: 10px;
  margin-bottom: 0;
}
.book-appointment .book_slot ul li {
  float: left;
  padding-right: 24px;
  border-right: 1px solid #fff;
  margin-right: 12px;
  list-style: none;
}
.book-appointment .book_slot ul li span {
  color: #ffffff;
  font-size: 14px;
  min-width: 67px;
}
.book-appointment .book_slot ul li p {
  padding-top: 5px;
  color: #fff;
  font-size: 14px;
}

.login {
  background-color: #f8f8f8;
}
.login .main-content {
  padding: 80px 0px;
}
@media (max-width: 526px) {
  .login .main-content {
    padding: 60px 0px;
  }
}
.login .main-content .card {
  padding: 70px;
  background-color: #1f1e06;
  border: 0px;
}
@media (max-width: 912px) {
  .login .main-content .card {
    padding: 50px 40px;
  }
}
@media (max-width: 526px) {
  .login .main-content .card {
    padding: 60px 20px;
  }
}
.login .main-content .card .main-h {
  color: #e4c499;
}
@media (max-width: 526px) {
  .login .main-content .card .main-h {
    font-size: 48px;
  }
}
.login.forgot-pass .main-content .card .main-h {
  font-size: 58px;
}
@media (max-width: 526px) {
  .login.forgot-pass .main-content .card .main-h {
    font-size: 48px;
  }
}

.client-story .main-content {
  padding: 80px 0px;
}
@media (max-width: 912px) {
  .client-story .main-content {
    padding: 80px 0px;
  }
}
@media (max-width: 526px) {
  .client-story .main-content {
    padding: 60px 20px 20px 20px;
  }
}
.client-story .main-content .one {
  position: relative;
}
@media (max-width: 526px) {
  .client-story .main-content .right-child {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.client-story .main-content .right-child.posFix {
  position: fixed;
  top: 25px;
  max-width: 20%;
}
@media (max-width: 912px) {
  .client-story .main-content .right-child.posFix {
    top: 94px;
  }
}
.client-story .main-content .right-child.posAbs {
  position: absolute;
  bottom: 25px;
}
@media (max-width: 912px) {
  .client-story .main-content .right-child.posAbs {
    bottom: 94px;
  }
}
.client-story .main-content .right-child .main-h {
  line-height: 82px;
  letter-spacing: 0.06em;
  margin-bottom: 20px;
  text-align: start;
  font-family: "Wulkan Display";
  font-weight: 600;
}
@media (max-width: 526px) {
  .client-story .main-content .right-child .main-h {
    line-height: 52px;
    text-align: center;
  }
}
.client-story .main-content .right-child .p-txt {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 40px;
}
.client-story .main-content .right-child .p-txt .doubt {
  font-weight: 500;
  text-decoration: underline;
}
@media (max-width: 912px) {
  .client-story .main-content .second-content .content {
    margin-left: 80px;
  }
}
@media (max-width: 526px) {
  .client-story .main-content .second-content .content {
    margin-left: 0px;
  }
}
.client-story .main-content .second-content .content.first {
  margin-top: 80px;
}
@media (max-width: 912px) {
  .client-story .main-content .second-content .content.first {
    margin-top: 0px;
  }
}
@media (max-width: 526px) {
  .client-story .main-content .second-content .content.first {
    margin-top: 0px;
    margin-left: 0px;
  }
}
.client-story .main-content .second-content .content .box {
  background: #fff;
  border-radius: 12px;
  padding: 40px 20px;
  margin-bottom: 40px;
}
.client-story .main-content .second-content .content .box .p-txt {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.06em;
}
.client-story .main-content .second-content .content .box .p-txt.name {
  font-size: 18px;
  line-height: 18px;
  color: #000;
  margin-top: 20px;
  font-family: "Wulkan Display";
  font-weight: 600;
}

.prev-btn {
  cursor: pointer;
}

.next-btn {
  cursor: pointer;
}

.prev-btn:disabled {
  cursor: not-allowed;
}

.page-item.disabled .page-link {
  cursor: not-allowed !important;
  pointer-events: all;
}/*# sourceMappingURL=style.css.map */